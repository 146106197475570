<template>
  <v-card>
    <div class="d-flex flex-column flex-sm-row align-center justify-sm-space-between">
      <v-card-title>
        Pay Using Your Card
      </v-card-title>
      <v-card-subtitle
        v-if="!!payableAmount"
        class="mt-0 mt-sm-3 font-weight-medium"
      >
        Payable Amount {{ payableAmount | currency }}
      </v-card-subtitle>
    </div>
    <v-divider />
    <v-card-text class="mt-3">
      <v-alert
        v-if="stripeValidationError"
        type="error"
      >
        {{ stripeValidationError }}
      </v-alert>

      <v-alert
        v-if="!!paymentFailed"
        type="error"
      >
        {{ paymentFailed }}
      </v-alert>

      <v-alert
        v-if="!clientSecret"
        type="warning"
      >
        there is an error in our payment gateway connection. <br>
        please try alternative method. or try later
      </v-alert>

      <div class="payment-form">
        <v-row>
          <v-col
            class="card-element"
            cols="12"
          >
            <label>Card Number</label>
            <div
              id="card-number"
              ref="cardElement"
            />
          </v-col>
          <v-col
            class="card-element"
            cols="6"
          >
            <label>Expiry Date</label>
            <div id="card-expiry" />
          </v-col>
          <v-col
            class="card-element"
            cols="6"
          >
            <label>CVC</label>
            <div id="card-cvc" />
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-divider />
    <v-card-text class="d-flex justify-end mt-3">
      <v-btn
        :disabled="paymentProcessing"
        color="primary"
        outlined
        text
        @click="cancelPayment"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!showButton"
        :loading="paymentProcessing"
        class="ml-5"
        color="primary"
        @click="processPayment()"
      >
        Pay now
        <v-icon right>
          mdi-send
        </v-icon>
      </v-btn>
    </v-card-text>
  </v-card>
</template>
<script>
import { loadStripe } from '@stripe/stripe-js';
import stripeConfig from '@/config/stripeConfig';

const stripeStyle = {
  base: {
    color: '#0d0925',
    fontWeight: 600,
    fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',

    ':focus': {
      color: '#424770',
    },

    '::placeholder': {
      color: '#9BACC8',
    },

    ':focus::placeholder': {
      color: '#CFD7DF',
    },
  },
  invalid: {
    color: '#FA755A',
    ':focus': {
      color: '#FA755A',
    },
    '::placeholder': {
      color: '#FFCCA5',
    },
  },
};
let elementClasses = {
  focus: 'focus',
  empty: 'empty',
  invalid: 'invalid',
};

export default {
  props: {
    clientSecret: {
      type: [String, Boolean],
    },
    amount: {
      type: [String, Number],
      required: false,
    },
    customerName: {
      type: String,
    }
  },
  data: () => ({

    stripeValidationError: null,
    paymentFailed: null,
    payableAmount: 0,

    token: null,
    cardNumber: null,
    cardExpiry: null,
    cardCvc: null,

    stripe: null,
    secret: null,
    paymentProcessing: false,
    client: null,

    validationStatus: {
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
    }
  }),
  computed: {
    stripeElements() {
      return this.stripe.elements();
    },
    showButton() {
      let hasError = !!this.stripeValidationError;

      if (!this.clientSecret) return false;

      if (hasError) return false;

      let isFilled = true;

      for (let key in this.validationStatus) {

        if (!this.validationStatus[key]) {
          isFilled = false;
          break;
        }

      }

      return isFilled;
    }
  },
  watch: {

    clientSecret: {

      handler(data) {
        this.secret = data;
      },

      immediate: true,
      deep: true,

    },

    amount: {

      handler(data) {
        this.payableAmount = data;
      },

      immediate: true,
      deep: true,
    },

    customerName: {

      handler(data) {
        this.client = data;
      },

      immediate: true,
      deep: true,
    }
  },
  async beforeMount() {

    let stripe = await loadStripe(stripeConfig.pk);
    this.stripe = stripe;
    this.makeStripeCardForm();

  },

  beforeDestroy() {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  },
  methods: {
    async processPayment() {

      let stripeSecret = this.secret;

      if (stripeSecret) {
        // :TODO There is an error Please try closing and re-opening
        // @return
      }

      let { cardNumber, cardExpiry, cardCvc } = this;

      let data = {
        payment_method: {
          card: {
            ...cardNumber,
            ...cardExpiry,
            ...cardCvc,

            billing_details: {
              name: this.client,
            }
          }
        }
      };

      this.paymentProcessing = true;

      try {

        let response = await this.stripe.confirmCardPayment(stripeSecret, data);
        this.paymentProcessing = false;
        this.handleSuccessTransaction(response);

      } catch (e) {
        this.paymentProcessing = false;
        this.handleFailedTransaction(e);
      }
    },

    makeStripeCardForm() {

      this.cardNumber = this.stripeElements.create('cardNumber', {
        style: stripeStyle,
        classes: elementClasses,
        showIcon: true,
      });

      this.cardExpiry = this.stripeElements.create('cardExpiry', {
        style: stripeStyle,
        classes: elementClasses
      });

      this.cardCvc = this.stripeElements.create('cardCvc', {
        style: stripeStyle,
        classes: elementClasses
      });

      this.cardNumber.mount('#card-number');
      this.cardExpiry.mount('#card-expiry');
      this.cardCvc.mount('#card-cvc');

      this.cardNumber.on('change', this.setValidationError);
      this.cardExpiry.on('change', this.setValidationError);
      this.cardCvc.on('change', this.setValidationError);

    },

    setValidationError(event) {

      this.validationStatus[event.elementType] = event.complete;

      if (event.error) this.paymentFailed = null;

      this.stripeValidationError = event.error ? event.error.message : null;
    },

    handleSuccessTransaction(data, previouslyPaid = false) {

      if (data.error && !previouslyPaid) {
        this.handleFailedTransaction(data.error);
        return;
      }

      this.$emit('payment-success', data);
    },

    cancelPayment() {
      this.$emit('cancelled', 'User Selected to Cancel the transaction');
    },

    handleFailedTransaction(error) {

      if (error?.paymentIntent?.status === 'succeeded') {
        this.handleSuccessTransaction(error?.paymentIntent, true);
        return;
      }

      this.paymentFailed = error.message;

    }
  }
};
</script>
<style lang="scss">

.payment-form {
  color: #0d0925;

  h5 {
    margin: 0;
    padding: 10px;
    font-size: 1.2rem;
  }

  #card-number,
  #card-expiry,
  #card-cvc {
    border: 1px solid #ececec;
    height: 40px;
    padding: 10px
  }
}
</style>
